import { graphql } from "gatsby";
import * as React from "react";
import BaseLayout from "../components/layouts/baseLayout";
import ProductCard from "../components/store/productCard";
import rose from "../images/rose.jpg";

interface IndexProps {
    data: {
        allMarkdownRemark: {
            edges: {
                node: {
                    id: string;
                    frontmatter: {
                        title: string;
                        description: string;
                        images: {
                            id: string;
                            absolutePath: string;
                            publicURL: string;
                        }[];
                        medium?: string;
                    };
                };
            }[];
        };
    };
}

const Index: React.FunctionComponent<IndexProps> = ({ data }) => (
    <BaseLayout>
        <div className="xl:py-48 lg:py-32 md:py-24 py-16 bg-green-400 text-gray-100">
            <div className="container mx-auto">
                <div className="grid xl:grid-cols-5 xl:gap-16 lg:grid-cols-2 lg:gap-8">
                    <div className="rounded-3xl shadow-xl overflow-hidden max-w-full xl:col-span-2 mx-8 lg:mx-0">
                        <img src={rose} className="w-full" />
                    </div>
                    <div className="xl:col-span-3 h-full flex flex-col justify-center">
                        <div>
                            <h1 className="text-8xl mb-16 text-center">
                                Rose Lange
                            </h1>

                            <h2 className="text-4xl text-gray-200 text-center">
                                Life is full of beautiful surprises
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="container mx-auto lg:px-16 px-8 py-24">
            <h2 className="text-5xl mb-8">Recent Work</h2>
            <div className="grid lg:grid-cols-3 lg:gap-16 md:grid-cols-2 md:gap-8">
                {data.allMarkdownRemark.edges.map(({ node }) => (
                    <ProductCard key={node.id} art={node} />
                ))}
            </div>
        </div>
    </BaseLayout>
);

export default Index;

export const pageQuery = graphql`
    query {
        allMarkdownRemark {
            edges {
                node {
                    id
                    frontmatter {
                        title
                        description
                        images {
                            id
                            absolutePath
                            publicURL
                        }
                        medium
                    }
                }
            }
        }
    }
`;
