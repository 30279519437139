import * as React from "react";

interface ProductCardProps {
    art: {
        id: string;
        frontmatter: {
            title: string;
            description: string;
            images: {
                id: string;
                absolutePath: string;
                publicURL: string;
            }[];
            medium?: string;
        };
    };
}

const ProductCard: React.FunctionComponent<ProductCardProps> = ({ art }) => {
    const media = art.frontmatter.medium?.split(",");
    return (
        <div className="rounded-3xl shadow-xl overflow-hidden bg-gray-200 my-8 md:my-0">
            {art.frontmatter.images && art.frontmatter.images.length > 0 && (
                <img src={art.frontmatter.images?.[0].publicURL} />
            )}
            <div className="p-6">
                <h2>{art.frontmatter.title}</h2>
                <p>{art.frontmatter.description}</p>
                {media && media.length > 0 && (
                    <div className="flex flex-wrap mt-3 gap-3">
                        {media.map((m, i) => (
                            <div
                                key={i}
                                className="shadow bg-gray-100 text-gray-700 px-3 py-1 rounded-full whitespace-nowrap"
                            >
                                {m.trim()}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ProductCard;
